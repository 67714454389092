import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import useAccountType from '@/helpers/useAccountType';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useModalControls } from '@/providers/modal';
import { Box, Button, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export default function CancelSubscriptionModal() {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { staff } = useUserInfo();
	const { closeModal } = useModalControls();
	const isCloverAccount = useAccountType( 'CLOVER' );
	
	return (
		<Stack sx={{ 'p': 5, '.cancelText, li': { fontSize: '16px !important' } }}>
			<Typography variant='h2'>
				{t( 'settings:confirm-cancel-subscription' )}
			</Typography>
			{isCloverAccount ? (
				<Box pb={10}>
					<Typography color='text.secondary' py={2} className='cancelText'>
						{t( 'settings:cancel-subscription-desc' )}
					</Typography>
					<Typography py={2} className='cancelText'>
						{t( 'settings:cancel-subscription-clover-1' )}
					</Typography>
					<Typography pt={2} pb={0} className='cancelText' fontWeight='bold'>
						{t( 'settings:cancel-subscription-clover-2' )}
					</Typography>
					<Typography py={2} pb={0} className='cancelText'>
						{t( 'settings:cancel-subscription-clover-3' )}
					</Typography>
					<ul>
						<li>{t( 'settings:invoices' )}</li>
						<li>{t( 'settings:orders' )}</li>
						<li>{t( 'settings:items' )}</li>
						<li>{t( 'settings:clients' )}</li>
						<li>{t( 'settings:payment-syncs' )}</li>
					</ul>
					<Typography py={2} className='cancelText'>
						{t( 'settings:cancel-subscription-clover-4' )} 🥳
					</Typography>
				</Box>
			) : (
				<Typography py={2} className='cancelText'>
					{t( 'settings:cancel-subscription-desc' )}
				</Typography>
			)}
			<Stack spacing={2}>
				<AsyncLoadingButton
					variant='outlined'
					color='error'
					size='large'
					onClick={async () => {
						try {
							const { data } = await axios.post( '/api/subscription/cancelSubscription', {
								id: staff?.company.id,
							} );
							if ( !isCloverAccount ) enqueueSnackbar( t( 'settings:subscription-canceled' ), { variant: 'success' } );
							if ( data ) window.open( 'https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2', '_blank' );
							closeModal();
						} catch ( e ) {
							console.error( e );
							closeModal();
							enqueueSnackbar( t( 'settings:subscription-cancel-error' ), { variant: 'error' } );
							throw e;
						}
					}}>
					{!isCloverAccount
						? t( 'settings:cancel-subscription' )
						: t( 'settings:cancel-subscription-clover' )}
				</AsyncLoadingButton>
				<Button size='large' color='primary' onClick={() => closeModal()}>
					{t( 'settings:keep-subscription' )}
				</Button>
			</Stack>
		</Stack>
	);
}
